<template>
	<div>
		<MenuFour :listName="'detail'"></MenuFour>
		<router-view></router-view>
	</div>
</template>

<script>
	import MenuFour from '../../../../components/menu/menu-four.vue' 
	export default {
		components: { MenuFour },
		data() {
			return { 
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="less" scoped></style>
